import React, { Fragment, useEffect, useState } from "react";
import HeadingComponent from "./headingComponent";
import BreadCrumbsComponent from "./breadCrumbsComponent";
import FirstStepComponents from '../firstStepComponents';
import SecondStepComponents from '../secondStepComponents';
import FinalStepComponents from '../finalStepComponents';
import { getLastSavedStep, getLastSavedNewSubRequest } from '../../helpers';
import LoaderComponent from '../../../subRequests/pages/components/loaderComponent/loaderComponent';
import TopButtonComponent from '../topButtonComponent';

export default function CreatePageSectionComponent(props) {
  const { user, business, path } = props
  const [ step, setStep ] = useState(getLastSavedStep(business?.id, user?.id, path) || 1)
  const [ loaded, setLoaded ] = useState(true)
  let lastSavedNewSubRequest = getLastSavedNewSubRequest(business?.id, user?.id)
  const [ newSubRequest, setNewSubRequest ] = useState(Object.keys(lastSavedNewSubRequest).length > 0 ? lastSavedNewSubRequest : {
    requestingInstructor: {id: user?.id, full_name: user?.full_name},
    approvedUser: {id: '', full_name: '', image: ''},
    locationId: '',
    timeFrame: '4_months',
    previousWeek: false,
    reason: {
      reason: '',
      otherReason: ''
    },
    schedules: [],
    comment: '',
    invitedInstructors: [],
    secondSkillCheck: [],
    mboSiteId: { site_id: '', site_name: '' }
  })

  return (
    <Fragment>
      <TopButtonComponent user={user} step={step} setStep={setStep} newSubRequest={newSubRequest}/>
      <div className="w-full px-0 xl:px-24 special-container-class">
        <div className="rounded-2xl border-2 border-gray-300 w-full bg-white py-6 px-5 md:px-8 h-full create-sub-request-page-custom-width">
          {!loaded &&
            <LoaderComponent loadingMessage={''}/>
          }
          {loaded &&
            <Fragment>
              <HeadingComponent step={step}/>
              <BreadCrumbsComponent step={step}/>
              {step == 1 &&
                <FirstStepComponents 
                  user={user} 
                  business={business} 
                  newSubRequest={newSubRequest}
                  setNewSubRequest={setNewSubRequest}
                  setStep={setStep}
                />
              }
              {step == 2 &&
                <SecondStepComponents 
                  user={user} 
                  business={business}
                  newSubRequest={newSubRequest}
                  setNewSubRequest={setNewSubRequest}
                  setStep={setStep}
                />
              }
              {step == 3 &&
                <FinalStepComponents
                  newSubRequest={newSubRequest}
                  user={user}
                  setStep={setStep}
                  business={business}
                />
              }
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
}