import React from "react";

export const helpingChecksForAPICall = (newSubRequest, business) => {
  const hasValidMboSiteId = newSubRequest?.mboSiteId?.site_id && newSubRequest?.mboSiteId?.site_id !== '';
  const multiSiteFeature = business?.multi_site_multi_staff_feature;

  return ((newSubRequest?.requestingInstructor?.id !== '' && (((business?.clubready_integration || business?.crunch_integration || business?.abc_integration) && newSubRequest?.locationId !== '') || (!business?.clubready_integration && !business?.crunch_integration && !business?.abc_integration))) && ((business?.mbo_integration && multiSiteFeature && hasValidMboSiteId) || !business?.mbo_integration || !multiSiteFeature))
}

export const getSortedData = (data) => {
  return data?.sort((a, b) => a.name.localeCompare(b.name));
}

export const getCurrentEndTime = () => {
  let currentTime = new Date();
  return new Date(currentTime.getTime() + 15 * 60000);
};

export const getBusinessMinDate = (user) => {
  let date = new Date()
  date.setDate(date.getDate() + user.block_before_days)
  return new Date(date)
}

export const getBusinessMaxDate = (user) => {
  let maxdate = new Date()
  maxdate.setDate(maxdate.getDate() + user.days_in_advance - 1)
  return new Date(maxdate);
}

export const getMinDate = (user, bizMinDate) => {
  return ((user.activate_past_request == true && user.role_display !== 'Fitness Instructor') ? '' : (bizMinDate ? bizMinDate : new Date()));
}

export const getMaxDate = (user, bizMaxDate) => {
  return ((user.activate_past_request == true && user.role_display !== 'Fitness Instructor') ? '' : (user?.days_in_advance > 0 ? (bizMaxDate ? bizMaxDate : new Date()) : ''));
}

const formatIntoDate = (date) => {
  let dateObj = new Date(date)
  return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
}
export const checkInstructorDaysInAdvanceCheckDate = (user, maxDate) => {
  if (user.days_in_advance > 0){
    if(user.sub_policy_check){
      return user.max_date
    }else{
      return formatIntoDate(maxDate)
    }
  }else{
    return ''
  }
}

export const checkInstructorDisableRows = (schedule, instructorDaysInAdvanceCheckDate, netgymSchedules, user) => {
  return (
    ((instructorDaysInAdvanceCheckDate !== '' && new Date(schedule?.date_new) > new Date(instructorDaysInAdvanceCheckDate))) ||
    (schedule?.up_untill_check) ||
    (netgymSchedules?.includes(schedule?.class_id)) ||
    (user?.blackout_dates_data?.includes(schedule.date_new))
  )
}

export const checkInstructorEligibalClasses = (schedules, instructorDaysInAdvanceCheckDate, netgymSchedules, user) => {
  let scheduleIds = []
  for(let i = 0; i < schedules?.length; i++){
    let check = (checkInstructorDisableRows(schedules[i], instructorDaysInAdvanceCheckDate, netgymSchedules, user))
    if(!check){
      scheduleIds.push(schedules[i]?.class_id)
    }
  }
  return (scheduleIds)
}

export const managerTooltipMessage = (schedule, netgymSchedules) => {
  return((netgymSchedules?.includes(schedule.class_id)) ? 'This class already has a sub request on the sub schedule. Click on class name to view.' : '')
}

export const instructorToolTipMessage = (schedule, user, instructorDaysInAdvanceCheckDate, netgymSchedules) => {
  return(
    (instructorDaysInAdvanceCheckDate !== '' && schedule.date_new > instructorDaysInAdvanceCheckDate) ? 
      user.instructor_days_in_advance_check_date_message 
    : 
    (schedule.up_untill_check == true) ? 
      user.request_sending_time_message 
    : 
    user.blackout_dates_data.includes(schedule.date_new) ? 
      user.blackout_check_message 
    : 
    netgymSchedules?.includes(schedule.class_id) ? 
      'This class already has a sub request on the sub schedule. Click on class name to view.' 
    : 
      ''
  )
}

export const getSchedulesData = (schedules) => {
  let location_ids = []
  let category_ids = []
  let class_ids = []
  let classes = []
  let class_titles = []
  for (let i = 0; i < schedules?.length; i++){
    location_ids.push(schedules[i]?.location_id)
    if(schedules[i]?.business_class_id == ''){
      class_titles.push(schedules[i]?.title)
    }
    if(schedules[i]?.business_category_id != '') category_ids.push(schedules[i]?.business_category_id)
    class_ids.push(schedules[i]?.business_class_id)
    classes.push({
      start_time: schedules[i]?.start_time, 
      end_time: schedules[i]?.end_time,
      formatted_start_time: schedules[i]?.formatted_start_time, 
      formatted_end_time: schedules[i]?.formatted_end_time, 
      date: schedules[i]?.date, 
      formatted_day: schedules[i]?.formatted_day, 
      class_id: schedules[i]?.business_class_id, 
      business_category_id: schedules[i]?.business_category_id, 
      business_location_id: schedules[i]?.location_id
    })
  }
  return ({location_ids: location_ids, category_ids: category_ids, class_ids: class_ids, classes: classes, class_titles: class_titles})
}

export const getFormatedDateTime = (date) => {
  if(date == '') return ('')
  let dateObj = new Date(date)
  let zoneString = date.toString().split('GMT')[1].split(' ')[0]
  return(`${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}T${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')}${zoneString}`)
}

export const formattedStartTime = (date) => {
  if(date == '') return ('')
  const fullDate = new Date(date);
  return(fullDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true, second: undefined }))
}

export const formatedDate = (date) => {
  if(date == '') return ('')
  const fullDate = new Date(date);
  return(fullDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' }))
}

export const formatedWeekDay = (date) => {
  if(date == '') return ('')
  const fullDate = new Date(date);
  return(fullDate.toLocaleDateString('en-US', { weekday: 'long' }))
}

function calculateDuration(startTime, endTime) {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const duration = end - start;
  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  return { hours, minutes };
}

export const formatClassesForSchedule = (newClasses) => {
  let shcedules = []
  for(let i = 0; i < newClasses.length; i++){
    let formatedStartTime = formattedStartTime(newClasses[i]?.selectedDateTime?.startTime)
    let startTime = getFormatedDateTime(newClasses[i]?.selectedDateTime?.startTime)
    let endTime = getFormatedDateTime(newClasses[i]?.selectedDateTime?.endTime)
    let duration = calculateDuration(startTime, endTime)
    let durationText = `${duration.hours > 0 ? `${duration.hours} hour` : ''} ${duration.minutes} min`
    let durationInMin = (duration.hours * 60) + duration.minutes
    let newHash = {
      location_id: newClasses[i]?.selectedLocation?.id,
      location: newClasses[i]?.selectedLocation?.name,
      business_category_id: newClasses[i]?.selectedClass?.business_category_id,
      title: newClasses[i]?.selectedClass?.name,
      business_class_id: newClasses[i]?.selectedClass?.id,
      business_class_name: newClasses[i]?.selectedClass?.name,
      start_time: startTime,
      formatted_start_time: formatedStartTime,
      formatted_end_time: formattedStartTime(newClasses[i]?.selectedDateTime?.endTime),
      date: formatedDate(newClasses[i]?.selectedDateTime?.eventDate),
      formatted_day: formatedWeekDay(newClasses[i]?.selectedDateTime?.eventDate),
      end_time: endTime,
      time: formatedStartTime,
      duration: durationText?.trim(),
      durationInMin: durationInMin,
      blockBeforeHours: newClasses[i]?.selectedDateTime?.blockBeforeHours,
      minDate: newClasses[i]?.selectedDateTime?.minDate
    }
    shcedules.push(newHash)
  }
  return(shcedules)
}

export const handleEmptyLocationError = (business, newSubRequest) => {
  return ((business?.clubready_integration || business?.crunch_integration || business?.abc_integration) && newSubRequest?.locationId === '')
}

export const handleEmptyMboSiteError = (business, newSubRequest) => {
  return (business?.mbo_integration && business?.multi_site_multi_staff_feature && newSubRequest?.mboSiteId?.site_id === '')
}

const handleDateInRequiredFormat = (dateString) => {
  // "Mon, Apr 1, 2024"
  let date = new Date(dateString)
  let weekDayName = date.toLocaleDateString('en-US', { weekday: 'short'})
  let monthName = date.toLocaleDateString('en-US', { month: 'short'})
  let dayNumber = date.toLocaleDateString('en-US', { day: 'numeric'})
  let year = date.toLocaleDateString('en-US', { year: 'numeric'})
  // "Wed 03/Apr/2024"
  return `${weekDayName} ${dayNumber}/${monthName}/${year}`
}

export const classesDataForIntegratedBusinesses = (schedules) => {
  if(schedules == undefined){return []}
  return(schedules?.map((item) => {return {"business_category_id": item?.business_category_id, "class_id": item?.class_id ? item?.class_id : item?.business_class_id, "title": item?.title}}))
}

export const classesDataForBasicBusinesses = (schedules) => {
  if(schedules == undefined){return []}
  return(schedules?.map((item) => {return {"date": handleDateInRequiredFormat(item?.date), "duration": item?.durationInMin, "time": item?.time, "business_class_id": item?.business_class_id, "business_location_id": item?.location_id}}))
}

export const getClassTitles = (schedules) => {
  if(schedules == undefined){return []}
  return(schedules?.filter((item) => item?.business_class_id == '' || item?.business_class_id == null).map((item) => {return item?.title}))
}

export const formattedErrorMessage = (message) => {
  return message?.replace(/\n/g, '<br/>')?.replace(/\t/g, '&nbsp;&nbsp;') || '';
}

export const saveSubRequestInLocalStorage = (subRequest, step, business_id, user_id) => {
  let data = {
    subRequest: subRequest,
    step: step,
    business_id: business_id,
    user_id: user_id
  }
  localStorage.setItem("subRequestInfoData", JSON.stringify(data));
}

export const getSubRequestFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("subRequestInfoData"))
}

export const getLastSavedStep = (business_id, user_id, path) => {
  if(path == 'create-sub-request'){
    return 1
  }else if(path == 'create-sub-request-sub-select'){
    let data = getSubRequestFromLocalStorage()
    if(data && data?.business_id == business_id && data?.user_id == user_id){
      if(data?.subRequest?.requesting_instructor_id == '' || data?.subRequest?.schedules?.length == 0 || data?.subRequest?.timeFrame == ''){
        setUrlByPageNo(1)
        return 1
      }
      if(data?.subRequest?.approvedUser?.id !== ''){
        setUrlByPageNo(3)
        return 3
      }
      return 2
    }else{
      setUrlByPageNo(1)
      return 1
    }
  }else if(path == 'create-sub-request-show'){
    let data = getSubRequestFromLocalStorage()
    if(data && data?.business_id == business_id && data?.user_id == user_id){
      if((data?.subRequest?.invitedInstructors?.length == 0 && data?.subRequest?.approvedUser?.id == '') || data?.subRequest?.requesting_instructor_id == '' || data?.subRequest?.schedules?.length == 0 || data?.subRequest?.timeFrame == ''){
        setUrlByPageNo(1)
        return 1
      }
      return 3
    }else{
      setUrlByPageNo(1)
      return 1
    }
  }
}

export const getLastSavedNewSubRequest = (business_id, user_id) => {
  let data = getSubRequestFromLocalStorage()
  if(data && data?.business_id == business_id && data?.user_id == user_id){
    return data?.subRequest || {}
  }else{
    return {}
  }
}

export const removeSubRequestFromLocalStorage = () => {
  let data = getSubRequestFromLocalStorage()
  if(data){
    localStorage.removeItem("subRequestInfoData");
  }
}

const formatTime = (dateTime) => {
  const time = dateTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: false, second: undefined })
  const [hour, minute] = time.split(':').map(Number);
  let suffix = 'AM';
  let formattedHour = hour;
  
  if (hour === 0) {
    formattedHour = 12;
  } else if (hour >= 12) {
    suffix = 'PM';
    if (hour > 12) {
      formattedHour = hour - 12;
    }
  }
  
  if (minute === 0) {
    return `${formattedHour} ${suffix}`;
  } else {
    return `${formattedHour}:${minute.toString().padStart(2, '0')} ${suffix}`;
  }
}

export const formatTimeRange = (startTime, endTime) => {
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);
  
  return `${formattedStartTime} - ${formattedEndTime}`;
}

export const updatePageUrl = (url) => {
  window.history.pushState(`path:${url}`, '', `/${url}`)
}

export const setUrlByPageNo = (pageNo) => {
  let path = 'create-sub-request'
  if(pageNo == 2) path = 'create-sub-request-sub-select'
  if(pageNo == 3) path = 'create-sub-request-show'
  updatePageUrl(path)
}

export const customSearchDropDownStyles = {
  menu: (provided) => (
    {
    ...provided,
    borderRadius: 16,
    right: 0,
    zIndex: 20
  }),
  menuList: (provided) => (
    {
    ...provided,
    borderRadius: 16
  }),
  option: (provided, state) => (
    {
    ...provided,
    active: {
      ...provided.active,
      backgroundColor: '#616161'
    },
    backgroundColor: state.isSelected ? '#616161' : state.isFocused ? '#e5e7eb' : 'white',
    color: state.isSelected ? 'white': 'black',
    fontSize: 14,
    cursor: 'pointer'
  })
}

export const getTheClassesHash = (allClasses) => {
  return allClasses.map((cls) => {
    return {
      value: cls.id,
      label: cls.name
    }
  })
}

export const getTheMboSitesHash = (mboSites) => {
  return mboSites.map((site) => {
    return {
      value: site.site_id,
      label: site.site_name
    }
  })
}

export const getTheUsersHash = (allUsers) => {
  return allUsers.map((usr) => {
    return {
      value: usr.id,
      label: usr.full_name,
      image: usr.image
    }
  }).sort((a, b) => a.label.localeCompare(b.label))
}

export const checkAndSetEmbedLink = (videoUrl) => {
  return videoUrl.replace('share', 'embed')
}