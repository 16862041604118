import React, { Fragment } from 'react'
import Cookies from 'js-cookie';
import { Switch, Route, Redirect } from "react-router-dom";
import TailwindLayout from './availability/layout';
import TailwindMessengerLayout from './messenger/messengerLayout/tailwindMessengerLayout'
import { getSudomain, getUserDeatails, getBusiness, getPermissions, checkCurrentUser, checkUserToken, fetchAccounts, removeSubdomain, checkSubdomainValue, checkConfirmUserPath } from './helpers'
import AlertPopup from './messenger/pages/chatComponents/assets/alertPopup';
import ContextProvider from './userComponents/contextProvider';
import UserContext from './userComponents/userContext';
import SignInLayout from './signIn/layout';
import ResetPasswordLayout from './resetPassword/layout';
import { axiosGetRequest } from './subRequests/helpers/helpers'
import SignUpLayout from './signUp/layout';
import IntercomWrapper from './intercom/intercomWrapper';
import { checkTheSignUpPagePath } from './signUp/helpers';
import MobileWebBanner from './mobileWebBanner';
 
class ChildComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: {},
      business: {},
      permissions: {},
      loadingMessage: "",
      loaded: false,
      path: '',
      accounts: [],
      show: false,
      alertType: '',
      alertMessage: '',
      subdomain: '',
      currentUrl: '',
      subdomainBusinessImage: '/assets/homepage/logo.png',
      userLoggedIn: false,
      appId: ''
    }
  }

  async setInitials() {
    let subdomain = getSudomain();
    let currentUserToken = Cookies.get(`currentUser.${subdomain}`)
    if (!currentUserToken || subdomain == 'home') {
      if (!['/forget-password', '/reset-password', '/email-confirmation'].includes(this.state.path)){
        this.setState({currentUrl: '/signin', userLoggedIn: false})
      }
      return
    }else if(currentUserToken && ['/forget-password', '/reset-password', '/email-confirmation'].includes(this.state.path)){
      this.setState({userLoggedIn: false})
      return
    }else if(currentUserToken && subdomain == 'console'){
      location.href = '/business_user#/console_home'
      return
    }
    getUserDeatails(currentUserToken, subdomain)
    .then(response => response.json())
    .then(result => { 
      if(result.status == 200){
        if(result.user?.active){
          this.setState({user: result.user, loadingMessage: window.getSettingSlogan(), userLoggedIn: true}, function () {
            this.permissions(this.state.user);
            window.setWalkMeData(result.user);
          });
          if(['/signin', '/', '/user-info', '/phone-verify', '/notifications', '/locations', '/daily-notifications', '/profile-pic'].includes(location.pathname)){
            this.setState({currentUrl: '/my-requests'})
          }
        }else{
          let path = checkTheSignUpPagePath(result.user)
          this.setState({currentUrl: path, loaded: true, user: result.user, loadingMessage: window.getSettingSlogan(), userLoggedIn: true}, function () {
            window.setWalkMeData(result.user);
          })
        }
      }else{
        this.setState({
          alertType: 'danger',
          alertMessage: result.error,
          show: true,
          userLoggedIn: false
        }, () => {
          window.setTimeout(()=>{
            this.setState({show:false})
            let domain = "."+window.location.hostname.replace(subdomain + '.', '' )
            Cookies.remove(`currentUser.${subdomain}`, { path: '/', domain: domain })
            localStorage.removeItem('businessID')
            if(result.error == 'No such domain found!'){
              this.setState({subdomain: ''})
              let splited_host = location.host.split('.')
              let new_host = splited_host[splited_host.length -2] + '.' + splited_host[splited_host.length -1]
              location.href = `${location.protocol}//${new_host}/signin`;
            }else{
              this.setState({currentUrl: '/signin'})
            }
          },3000)
        });
      }
    })
  }

  componentDidMount = () => {
    checkCurrentUser()
    // checkUserToken()
    this.getfolderName()
    this.checkBusinessSubdomain()
  }

  checkBusinessSubdomain = () => {
    this.setState({loaded: false})
    let folderPath = location.pathname
    if(['/talk', '/select', '/login', '/'].includes(folderPath)){
      this.setState({currentUrl: '/signin'})
    }
    let subdomain = getSudomain()
    if(checkSubdomainValue(subdomain) && subdomain !== 'home'){
      if (subdomain !== 'console') {
        this.checkData(subdomain)
      }else{
        this.setLoaded()
      }
    }else{
      if(checkSubdomainValue(subdomain) && subdomain == 'home'){
        removeSubdomain()
        return
      }
      this.setState({currentUrl: '/signin'})
    }
    this.setState({subdomain: subdomain})
  }

  async checkData(subdomain) {
    await this.checkSubdomainPresent(subdomain)
  }

  async checkSubdomainPresent(subdomain){
    let url = `/api/v3/business/check_presence?subdomain=${subdomain}`
    axiosGetRequest(url)
    .then((result) => {
      if(result.status !== 200){
        this.setState({
          alertType: 'danger',
          alertMessage: result.error,
          show: true
        }, () => {
          window.setTimeout(()=>{
            removeSubdomain()
          },2000)
        });
      }else{
        this.setState({subdomainBusinessImage: result.business_image, appId: result.app_id})
        if(!localStorage.getItem("businessID")){
          localStorage.setItem("businessID", result.business_id);
        }
        if(checkConfirmUserPath()){
          this.setState({loaded: true})
        }else if (this.resetPasswordPath()){
          this.setState({
            loaded: true,
            loadingMessage: window.getSettingSlogan()
          })
        }else{
          this.setLoaded()
        }
      }
    })
  }

  resetPasswordPath = () => {
    return location.pathname == '/reset-password';
  }

  getfolderName = () => {
    let folderPath = location.pathname
    this.setState({
      path: folderPath
    })
  }

  permissions = (user) => {
    getPermissions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), user.role)
    .then(response => response.json())
    .then(result => { 
      this.setState({
        permissions: result.permissions,
        // loaded: true,
        // loadingMessage: window.getSettingSlogan()
      })
      this.getBusinessData()
    })
  }

  fetch = () => {
    fetchAccounts(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
    .then(response => response.json())
    .then(result => {
      if(result.status == 200){
        this.setState({
          accounts: result.business_users,
          // loaded: true,
        })
      }
    })
  }

  getBusinessData = () => {
    // Wait for the get Business API response and set to State
    getBusiness(Cookies.get(`currentUser.${getSudomain()}`),  getSudomain()).then(response => response.json())
    .then(result => {      
      localStorage.setItem("businessID", result.business.id);
      this.setState({
        business: result.business,        
      }, function () {
        // this.fetch();
        this.setState({
          loaded: true,
        })
      })
    })   
  }

  async setLoaded() {
    await this.setInitials()
  }

  getCurrentUser = () => {
    getUserDeatails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
    .then(response => response.json())
    .then(result => { 
      this.setState({user: result.user});
    })
  }

  handleUpdateUser = (data) => {
    this.setState({
      user: data
    })
  }

  closeAlert = (data) => {
    this.setState({
      show: data,
      alertType: '',
      alertMessage: ''
    })
  }

  updateUserData = (data, path) => {
    this.setState({
      user: data,
      loaded: false,
      currentUrl: path == this.state.currentUrl ? '' : path,
      userLoggedIn: true
    },()=>{
      window.setTimeout(()=>{
        if(path !== this.state.currentUrl){
          this.setState({currentUrl: path})
        }
      },100)
    })
    this.permissions(data);
    window.setWalkMeData(data)
  }

  handleReIntializeAllApiCalls = () => {
    this.setLoaded()
  }

  render() {
    let { user, business, permissions, loadingMessage, loaded, path, accounts, show, alertType, alertMessage, subdomain, currentUrl, subdomainBusinessImage, userLoggedIn, appId } = this.state
    return (
      <Fragment>
        <MobileWebBanner />
        <IntercomWrapper isLoggedIn={userLoggedIn} user={user} appId={appId}>
          <Fragment>
            {['/signin', '/my-requests', '/user-info', '/phone-verify', '/notifications', '/locations', '/daily-notifications', '/profile-pic'].includes(currentUrl) &&
              <Redirect to={currentUrl} />
            }   
            {show &&
              <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert}/>
            }
            <Switch>
              <Route exact path="/availability" 
                component={() => 
                  <TailwindLayout 
                    path = 'index'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/availability/edit/:role" 
                component={() => 
                  <TailwindLayout 
                    path = 'edit'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/directory" 
                component={() => 
                  <TailwindLayout 
                    path = 'directory'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/availability/edit" 
                component={() => 
                  <TailwindLayout 
                    path = 'edit'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/availability/search" 
                component={() => 
                  <TailwindLayout 
                    path = 'search'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/resource_hub" 
                component={() => 
                  <TailwindLayout
                    path = 'resource_hub'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/resource_hub" 
                component={() => 
                  <TailwindLayout
                    path = 'resource_hub'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/resource_hub/add-file" 
                component={() => 
                  <TailwindLayout
                    path = 'add-file'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/*/add-file" 
                component={() => 
                  <TailwindLayout
                    path = 'add-file'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/resource_hub/add-file/publish/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'publish'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/*/publish/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'publish'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/resource_hub/view-resource-detail/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'view-resource-detail'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/*/view-resource-detail/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'view-resource-detail'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/resource_hub/folder/*" 
                component={() => 
                  <TailwindLayout
                    path = 'view-folder-detail'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'messenger'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/inbox" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'inbox'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/create-post" 
                component={() => 
                  <TailwindLayout
                    path = 'create-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    accounts={ accounts}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/messenger/show-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'show-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/compliance" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'compliance'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/compliance/show-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'show-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/announcement" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'announcement'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/event" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'event'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/announcement/show-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'show-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/event/show-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'show-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/permanent_class_opening" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'permanent_class_opening'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/permanent_class_opening/show-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'show-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/archive" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'archive'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/archive/show-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'show-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/messenger/chat-room/:id" 
                component={() => 
                  <TailwindMessengerLayout
                    path = 'chat-room'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/*/edit-post/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'create-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    accounts={ accounts}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/messenger/edit-chat-room/:id" 
                component={() => 
                  <TailwindLayout
                    path = 'create-post'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    accounts={ accounts}
                    pageLoaded={loaded}
                  />
                }
              />
              {(path && path?.split('/')[1] === 'resource_hub' && path?.split('/')[2] === 'folder') &&
                <Route exact path={`${path}/:name`}
                  component={() => 
                    <TailwindLayout
                      path = 'view-folder-detail'
                      user = {user}
                      business = {business}
                      permissions={permissions}
                      loadingMessage={loadingMessage}
                      pageLoaded={loaded}
                      accounts={ accounts}
                    />
                  }
                />
              }
              <Route exact path="/settings-v4"
                component={() => 
                  <TailwindLayout 
                    path = 'users'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                    setLoaded={this.getCurrentUser}
                  />
                }
              />
              <Route exact path="/settings-v4/users"
                component={() => 
                  <TailwindLayout 
                    path = 'users'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                    setLoaded={this.getCurrentUser}
                  />
                }
              />
              <Route exact path="/settings-v4/add-user"
                component={() => 
                  <TailwindLayout 
                    path = 'add-users'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/locations"
                component={() => 
                  <TailwindLayout 
                    path = 'locations'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/classes"
                component={() => 
                  <TailwindLayout 
                    path = 'classes'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/regions"
                component={() => 
                  <TailwindLayout 
                    path = 'regions'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/skills"
                component={() => 
                  <TailwindLayout 
                    path = 'skills'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/permissions"
                component={() => 
                  <TailwindLayout 
                    path = 'permissions'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/permissions/form/:role"
                component={() => 
                  <TailwindLayout 
                    path = 'permissionsForm'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/settings-v4/account"
                component={() => 
                  <TailwindLayout 
                    path = 'account'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/netgym-settings"
                component={() => 
                  <TailwindLayout 
                    path = 'netgym-settings'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure"
                component={() => 
                  <TailwindLayout 
                    path = 'netgym-settings'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/mbo-details"
                component={() => 
                  <TailwindLayout 
                    path = 'mbo-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/abc-details"
                component={() => 
                  <TailwindLayout 
                    path = 'abc-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/mariana-details"
                component={() => 
                  <TailwindLayout 
                    path = 'mariana-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/crunch-details"
                component={() => 
                  <TailwindLayout 
                    path = 'crunch-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/fisikal-details"
                component={() => 
                  <TailwindLayout 
                    path = 'fisikal-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/configure/club-ready-details"
                component={() => 
                  <TailwindLayout 
                    path = 'club-ready-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/notification-audits"
                component={() => 
                  <TailwindLayout 
                    path = 'notification-audits'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/my-profile"
                component={() => 
                  <TailwindLayout 
                    path = 'my-profile'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                    handleUpdateUser={this.handleUpdateUser}
                    getCurrentUser={this.getCurrentUser}
                  />
                }
              />
              <Route exact path="/analytics"
                component={() => 
                  <TailwindLayout 
                    path = 'analytics'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/all-requests"
                component={() => 
                  <TailwindLayout 
                    path = 'all-requests'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/my-requests"
                component={() => 
                  <TailwindLayout 
                    path = 'my-requests'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/sub-request-details/:id"
                component={() => 
                  <TailwindLayout 
                    path = 'sub-request-details'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/create-sub-request"
                component={() => 
                  <TailwindLayout 
                    path = 'create-sub-request'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/create-sub-request-sub-select"
                component={() => 
                  <TailwindLayout 
                    path = 'create-sub-request-sub-select'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/create-sub-request-show"
                component={() => 
                  <TailwindLayout 
                    path = 'create-sub-request-show'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/attendance"
                component={() => 
                  <TailwindLayout 
                    path = 'attendance'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
              <Route exact path="/signin"
                component={() => 
                  <SignInLayout 
                    path = {subdomain && subdomain !== null && subdomain !== '' && subdomain !== 'home' ? 'login' : 'signin'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    updateUserData = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                  />
                }
              />
              <Route exact path="/confirm-user"
                component={() => 
                  <SignUpLayout 
                    path = {'confirm-user'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/user-info"
                component={() => 
                  <SignUpLayout 
                    path = {'user-info'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/phone-verify"
                component={() => 
                  <SignUpLayout 
                    path = {'phone-verify'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/notifications"
                component={() => 
                  <SignUpLayout 
                    path = {'notifications'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/locations"
                component={() => 
                  <SignUpLayout 
                    path = {'locations'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/daily-notifications"
                component={() => 
                  <SignUpLayout 
                    path = {'daily-notifications'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/profile-pic"
                component={() => 
                  <SignUpLayout 
                    path = {'profile-pic'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/forget-password"
                component={() => 
                  <SignInLayout 
                    path = 'forget-password'
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    updateUserData = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                  />
                }
              />
              <Route exact path="/reset-password"
                component={() => 
                  <ResetPasswordLayout 
                    path = 'reset-password'
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    updateUserData = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                    loadingMessage={loadingMessage}
                  />
                }
              />
              <Route exact path="/email-confirmation"
                component={() => 
                  <SignUpLayout 
                    path = {'email-confirmation'}
                    subdomain = {subdomain}
                    user = {user}
                    business = {business}
                    handleUpdateUser = {this.handleUpdateUser}
                    updateUserDataAndRedirect = {this.updateUserData}
                    subdomainBusinessImage={subdomainBusinessImage}
                    handleReIntializeAllApiCalls={this.handleReIntializeAllApiCalls}
                    pageLoaded={loaded}
                  />
                }
              />
              <Route exact path="/certifications"
                component={() => 
                  <TailwindLayout 
                    path = 'certifications'
                    user = {user}
                    business = {business}
                    permissions={permissions}
                    loadingMessage={loadingMessage}
                    pageLoaded={loaded}
                    accounts={ accounts}
                  />
                }
              />
            </Switch>       
          </Fragment>
        </IntercomWrapper>
      </Fragment>
    )
  }
}

export default class TailwindApp extends React.Component { 
  static contextType = UserContext; 
  render() {
    return (
      <ContextProvider>
        <ChildComponent />
      </ContextProvider>
    );
  }
}
