import React from "react";
export const photosFilterMethod = (attachment) => {
  return (attachment.type === 'image' || attachment.type === 'video') && !attachment.title_link && !attachment.og_scrape_url && (attachment.image_url || attachment.thumb_url)
}

export const linksFilterMethod = (attachment) => {
  return (attachment.type === 'image' && (attachment.title_link || attachment.og_scrape_url) && (attachment.image_url || attachment.thumb_url))
}

export const EmptyListComponent = ({type}) => {
  return (
    <div className="text-sm text-black font-semibold">
      No {type} have been shared yet
    </div>
  )
}

export const extractAttachments = (messages, filterFn) => {
  return messages.reduce((acc, cur) => {
    const attachments = cur.attachments?.filter(filterFn) || []

    return [...acc, ...attachments]
  }, [])
}

const attachmentFilters = { 'attachments.type': { $in: ['image', 'video'] }, deleted_at: { $exists: false } }
const pinnedFilters = { pinned: true, deleted_at: { $exists: false } }

export const fetchFilteredMessages = async (client, channelCID, type = 'attachments') => {
  const limit = 100;
  let offset = 0;
  let allMessages = [];
  let hasMore = true;
  let typeFilter = ((type == 'attachments') ? attachmentFilters : pinnedFilters)

  while (hasMore) {
    try {
      const res = await client.search(
        {
          cid: { $in: [channelCID] },
        },
        typeFilter,
        {
          limit,
          offset,
        }
      );
      const newMessages = res?.results.map((result) => result.message) || [];
      allMessages = [...allMessages, ...newMessages];
      hasMore = newMessages.length === limit;
      offset += limit;
    } catch (error) {
      console.error('Error fetching messages:', error);
      hasMore = false;
    }
  }

  return allMessages;
};