import React from 'react'
import { useMessageContext } from 'stream-chat-react'

export default function ChannelNotificationTimeStamp(messageCreatedAt){
  const messageCreatedAtString = messageCreatedAt.toDateString()
  const todaysDateTimeStamp = new Date()
  const todaysDateString = todaysDateTimeStamp.toDateString()

  function getDayName(dateStr, locale){
    var date = new Date(dateStr);
    return date.toLocaleDateString(undefined, { weekday: 'long' });        
  }

  function isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
  
    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
  
    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
  
    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  const letsTalkAboutDate = () => {
    if(todaysDateString === messageCreatedAtString){
      return messageCreatedAt?.toLocaleTimeString(undefined, {hour12: true, hour: 'numeric', minute: 'numeric'})
    }else{
      const yesterdayDate = new Date(todaysDateString)
      yesterdayDate.setDate(yesterdayDate.getDate() - 1); 
      const yseterdayDateString = yesterdayDate.toDateString()

      const tomorrowDate = new Date(todaysDateString)
      tomorrowDate.setDate(tomorrowDate.getDate() + 1); 
      const tomorrowDateDateString = tomorrowDate.toDateString()
      if(yseterdayDateString === messageCreatedAtString){
        return 'Yesterday'+' - '+messageCreatedAt?.toLocaleTimeString(undefined, {hour12: true, hour: 'numeric', minute: 'numeric'})
      }else if(tomorrowDateDateString === messageCreatedAtString){
        return 'Tomorrow'+' - '+messageCreatedAt?.toLocaleTimeString(undefined, {hour12: true, hour: 'numeric', minute: 'numeric'})
      }else{
        if(isDateInThisWeek(messageCreatedAt)){
          return getDayName(messageCreatedAt, "nl-NL")+' - '+messageCreatedAt?.toLocaleTimeString(undefined, {hour12: true, hour: 'numeric', minute: 'numeric'})
        }else{
          return messageCreatedAt.toLocaleDateString('en-US')+' - '+messageCreatedAt?.toLocaleTimeString(undefined, {hour12: true, hour: 'numeric', minute: 'numeric'})
        }
      }
    }
    
  }
  
  return ( letsTalkAboutDate()
  );
} 