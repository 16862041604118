import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { setTitle } from '../../helpers';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';

class CertificationsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showAlert: false,
      alertType: '',
      alertMessage: ''
    };
  }

  componentDidMount = () => {
    setTitle('certifications');
    this.props.setActive('Certifications');
  }

  render() {
    const { loaded, showAlert, alertType, alertMessage } = this.state;

    return (
      <Fragment>
        {showAlert &&
          <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert} />
        }
        <div className={`w-full pt-20 md:pt-8 min-h-screen font-open-sans bg-gray-custom-50 px-8`}>
          <div>
            <header>
              <h1 className="text-2xl font-semibold mb-5 leading-tight text-gray-900">Certifications</h1>
            </header>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(CertificationsLayout);