import React, { Fragment, useState } from "react";
import { PhoneIcon, EnvelopeIcon, ChatIcon, TextIcon } from './../../../../subRequests/assets';
import { axiosRequest } from './../../../../subRequests/helpers/helpers'

export default function InstructorActionButtonsComponent(props) {
  const { sub, redirectToUrl, handleAlert } = props
  const [ createDmBtnDisabled, setCreateDmBtnDisabled ] = useState(false)

  const handleCallButton = (val, type) => {
    if(type == 'call'){
      window.location.href = `tel:${val}`
    }else if(type == 'mail'){
      window.location.href = `mailto:${val}`
    }else if(type == 'text'){
      window.location.href = `sms:${val}`
    }else if(type == 'chat'){
      handleProcessCreateDM(val)
    }
  }

  const handleProcessCreateDM = (id) => {
    setCreateDmBtnDisabled(true)
    if(!id || id == '' || id == 0) return
    let receivers = []
    receivers.push(id)
    const formData = new FormData()
    formData.append('group_data[message]', '');
    formData.append('group_data[type]', 'direct_message');
    formData.append('group_data[receivers]', receivers);
    formData.append('group_data[is_private]', true);
    let url = `/api/v3/messenger/create_group`
    axiosRequest(url, 'POST', formData, 'content').then(result => {
			if(result.status === 200){
        handleAlert(true, result?.message, 'success')
        window.setTimeout(()=>{
          setCreateDmBtnDisabled(false)
          redirectToUrl(`/messenger/chat-room/${result?.id}`, true)
        },100)
			}else{
        handleAlert(true, result.error, 'danger')
        setCreateDmBtnDisabled(false)
        window.setTimeout(()=>{
          handleAlert(false, '', '')
        },3000)
			}
		})
  }

  return(
    <Fragment>
      <div className="hidden lg:flex items-center gap-2">
        {sub?.show_phone &&
          <Fragment>
            <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(sub?.phone, 'text')}>
              <div className="flex items-center">
                <TextIcon classNames={'h-4 w-4'} />
              </div>
              Text
            </button>
            <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(sub?.phone, 'call')}>
              <div className="flex items-center">
                <PhoneIcon classNames={'h-4 w-4'} />
              </div>
              Call
            </button>
          </Fragment>
        }
        {sub?.show_email &&
          <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(sub?.email, 'mail')}>
            <div className="flex items-center">
              <EnvelopeIcon classNames={'h-4 w-4'} />
            </div>
            Mail
          </button>
        }
        {sub?.create_dm_permission &&
          <button className={`flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100 ${createDmBtnDisabled ? 'cursor-not-allowed' : ''}`} onClick={() => handleCallButton(sub?.id, 'chat')} disabled={createDmBtnDisabled}>
            <div className="flex items-center">
              <ChatIcon classNames={'h-4 w-4'} />
            </div>
            Chat
          </button>
        }
      </div>
    </Fragment>
  )
}