import React, { Fragment, useState, useEffect } from "react";
import Select from 'react-select';
import { axiosGetRequest } from './../../../../../subRequests/helpers/helpers';
import { customSearchDropDownStyles } from '../../../../helpers';
import { getTheMboSitesHash } from '../../../../helpers';
import { LocationPinIcon } from '../../../../../subRequests/assets';

export default function MboSiteIdComponent(props) {
  const { newSubRequest, setNewSubRequest } = props;
  const [ selectedMboSite, setSelectedMboSite ] = useState(newSubRequest?.mboSiteId || {})
  const [ selectedOption, setSelectedOption ] = useState(selectedMboSite?.site_id !== '' ? {value: selectedMboSite?.site_id, label: selectedMboSite?.site_name} : null)
  const [ mboSiteIdOptions, setMboSiteIdOptions ] = useState([]);
  const [inputValue, setInputValue] = useState(selectedMboSite?.site_id !== '' ? selectedMboSite?.site_name : '');
  const [loading, setLoading] = useState(false);
  const [ requestingInstId, setRequestingInstId ] = useState('');

  useEffect(() => {
    if (newSubRequest?.requestingInstructor?.id !== '' && parseInt(newSubRequest?.requestingInstructor?.id) !== parseInt(requestingInstId)) {
      setRequestingInstId(parseInt(newSubRequest?.requestingInstructor?.id))
      fetchMboSiteIds()
    }
  }, [newSubRequest?.requestingInstructor])

  useEffect(() => {
    setSelectedMboSite(newSubRequest?.mboSiteId || {})
  }, [newSubRequest?.mboSiteId])

  const fetchMboSiteIds = () => {
    setLoading(true);
    axiosGetRequest(`/api/v3/fetch_mbo_site_ids?requesting_instructor_id=${newSubRequest?.requestingInstructor?.id}`).then(result => {
      setLoading(false);
      if (result?.status === 200) {
        const mboSites = result?.results;
        setMboSiteIdOptions(getTheMboSitesHash(mboSites));
      
        const hasSelectedSite = selectedMboSite?.site_name === '' && selectedMboSite?.site_id !== '';
        const singleSite = mboSites?.length === 1;
      
        let selectedSite = mboSites.find(site => site.site_id === selectedMboSite?.site_id);
      
        if (hasSelectedSite) {
          if (selectedSite) {
            setSelectedOption({ value: selectedSite?.site_id, label: selectedSite?.site_name });
            setInputValue(selectedSite?.site_name);
          }
        } else if (singleSite) {
          const site = mboSites[0];
          saveMboSiteId(site.site_id, site.site_name);
          setSelectedOption({ value: site.site_id, label: site.site_name });
          setInputValue(site.site_name);
        } else {
          if (selectedSite) {
            setSelectedOption({ value: selectedSite?.site_id, label: selectedSite?.site_name });
            setInputValue(selectedSite?.site_name);
          } else {
            saveMboSiteId('', '');
            setSelectedOption(null);
            setInputValue('');
          }
        }
      }      
    })
  }

  const handleMenuOpen =  (e) => {
    $(`.mbo-site-need-border`).addClass('filter-dynamic-border')
  }

  const handleMenuClose =  (e) => {
    $(`.mbo-site-need-border`).removeClass('filter-dynamic-border')
    processBackFill()
  }

  const processBackFill = () => {
    if(inputValue == '' || selectedOption == null){
      setSelectedOption(selectedMboSite?.site_id !== '' ? {value: selectedMboSite?.site_id, label: selectedMboSite?.site__name} : null)
      setInputValue(selectedMboSite?.site_id !== '' ? selectedMboSite?.site_name : '');
    }else if(inputValue !== selectedOption.label){
      setInputValue(selectedMboSite?.site_id !== '' ? selectedMboSite?.site_name : '');
    }
  }

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      if(newValue !==  ''){
        setInputValue(newValue);
      }else{
        setInputValue('');
        setSelectedOption(null);
      }
    }
    return newValue;
  };

  const handleSelectedOption = (e) => {
    let label = e ? e.label : ''
    let value = e ? e.value : ''
    saveMboSiteId(value, label)
    setSelectedOption(e)
    setInputValue(label);
  }

  const saveMboSiteId = (siteId, siteName) => {
    let selectedMboSiteHash = {site_id: siteId, site_name: siteName}
    setSelectedMboSite(selectedMboSiteHash)
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      mboSiteId: selectedMboSiteHash
    }))
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 filter-column justify-end">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Mbo Site
        </div>
        <div className="relative filters_box">
          <LocationPinIcon classNames={'w-5 h-5 text-neutral-500 absolute top-3 left-3 z-10'}/>
          <Select
            className={`flex justify-center w-full rounded-2xl pl-8 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent mbo-site-need-border class-container`}
            classNamePrefix="r-select"
            placeholder="Select mbo site"
            value={selectedOption}
            onChange={handleSelectedOption}
            options={mboSiteIdOptions}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            styles={customSearchDropDownStyles}
            inputValue={inputValue}
            defaultInputValue={inputValue}
            onInputChange={handleInputChange}
            isLoading={loading}
          />
        </div>
      </div>
    </Fragment>
  )
}