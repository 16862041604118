import React from 'react';
import { Switch } from '@headlessui/react'
import { classNames } from '../../../helpers'

export default function ContactInfo(props){
  const { user, showPhoneInDirectory, setShowPhoneInDirectory, setEditContactInfo } = props

  const handleEditContactInfo = (data) => {
    setEditContactInfo(data)
  }

  const handleSwitch = (e, name) => {
    setShowPhoneInDirectory(!showPhoneInDirectory)
  }

  return(
    <React.Fragment>
      <div className='bg-white flex flex-col rounded-md shadow px-6 py-7 mb-5'>
        <div className="flex items-center mb-4 pb-1">
          <div className="text-lg font-medium flex-auto">SMS Notifications</div>
          {user?.directory_phone_number && user?.directory_phone_number !== '' &&
            <button className="flex bg-dark-blue text-sm text-white py-2 px-4 rounded-md h-10 items-center" onClick={() => handleEditContactInfo(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
              Edit
            </button>
          }
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Phone Number
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-1 flex'>
            <div className='text-sm font-normal'>
              {user?.directory_phone_number}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}