import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { hideItems, updateAnalyticsLocation, axiosGetExportRequest } from './../../helpers'
import DatePicker from "react-datepicker";
import { TruckOutline } from 'heroicons-react';
import Cookies from 'js-cookie';
import { getSudomain } from './../../../helpers'
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";

export default function FiltersComponent (props){
  const { analyticsRegionLocations, analyticsInstructors, historyUserId, historyUserName, historyStartDate, historyEndDate, handleClickOnSearch, setTheSelectedRegions, setTheSelectedLocations, setTheSearchText, setHistoryStartDate, setHistoryEndDate, activeTab, showPopup, behaviorTheCol1Part1, behaviorTheCol1Part2, behaviorTheCol2Part1, behaviorTheCol2Part2, behaviorTheCol3Part1, behaviorTheCol3Part2, behaviorTheCol4, sortType, sortReverse, handleOpenBehaviorColumSettingPopup } = props

  const date = new Date();
  date.setMonth(date.getMonth() - 1, 1);

  const [ regionLocations, setRegionLocations ] = useState(analyticsRegionLocations?.regions_and_locations || [])
  const [ selectedLocations, setSelectedLocations ] = useState(analyticsRegionLocations?.business_location_ids || [])
  const [ selectedRegions, setSelectedRegions ] = useState(analyticsRegionLocations?.business_region_ids || [])
  const [ showFilterOther, setShowFilteOther ] = useState(analyticsRegionLocations?.show_others || false)
  
  const [ startDate, setStartDate ] = useState(date)
  const [ endDate, setEndDate ] = useState(new Date())
  const [ searchText, setSearchText ] = useState('')

  const [ analyticsInstructor , setAnalyticsInstructor ] = useState([])
  const [ showAnalyticsInstructorslist, setShowAnalyticsInstructorslist ] = useState(false)

  const selectedAllRegLoc = () => {
    let check = selectedRegions?.length === regionLocations?.length
    return check
  }

  const selectAll = (e) => {
    if(e.target.checked){
      let all = regionLocations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['id'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      setTheSelectedLocations(selected_locations)
      setSelectedLocations(selected_locations)
      setTheSelectedRegions(selected_regions)
      setSelectedRegions(selected_regions)
    }else{
      setTheSelectedLocations([])
      setSelectedLocations([])
      setSelectedRegions([])
      setTheSelectedRegions([])
    }
  }

  const selectRegions = (e, region) => {
    let val = parseInt(e.target.value)

    let regions = selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        setTheSelectedRegions([...selectedRegions, val])
        setSelectedRegions([...selectedRegions, val])
        // selected_locations = Array.prototype.push.apply(selected_locations, result)
        setTheSelectedLocations([...selected_locations, ...result])
        setSelectedLocations([...selected_locations, ...result])
      }
    }else{
      if(__FOUND){
        setTheSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
        selected_locations = selected_locations.filter(item => !result.includes(item));
        setTheSelectedLocations(selected_locations)
        setSelectedLocations(selected_locations)
      }
    }
  }

  const selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...selectedLocations, val]
        setTheSelectedLocations(newlocs)
        setSelectedLocations(newlocs)
        checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        let newLocs = selectedLocations.filter((tag, index) => tag !== val) 
        setTheSelectedLocations(newLocs)
        setSelectedLocations(newLocs) 
        checkRegionVisibility(region, newLocs)  
      }      
    }
    
  }

  const checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.id

    let regions = selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        setTheSelectedRegions([...selectedRegions, val])
        setSelectedRegions([...selectedRegions, val])
      }
    }else{
      if(__FOUND){
        setTheSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
      }
    }
  } 

  const setSelLocations = () => {
    let locations = selectedLocations;
    if(locations.length === 0){
      return;
    }
    
    updateAnalyticsLocation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), selectedLocations, selectedRegions, true).then(      
      response => response.json()
    )
    .then(result => {
			if(result.status === 200){
        showPopup(true, 'success', result?.message)
        window.setTimeout(() => {
          showPopup(false, '', '')
        }, 3000)
      }else{
        showPopup(true, 'danger', result?.error)
        window.setTimeout(() => {
          showPopup(false, '', '')
        }, 3000)
      }
    })
  }

  const cancelLocationFilter = () => {
    hideItems('location')
  }

  const handleStartDateChange = (e) => {
    setStartDate(e === null ? '' : e)
    setHistoryStartDate(e === null ? '' : e)
  }

  const handleEndDateChange = (e) => {
    setEndDate(e === null ? '' : e)
    setHistoryEndDate(e === null ? '' : e)
  }

  const handleSearchResult = () =>{
    handleClickOnSearch(selectedLocations, selectedRegions, startDate, endDate, searchText)
  }

  const handleExportData = () => {
    if (startDate == "" || endDate == ""){
      console.log("Start and end date required ...")
      return
    }
    let url = ""
    let file_name = ""
    if (activeTab == 'behavior'){
      if(behaviorTheCol1Part1 === "" || behaviorTheCol1Part2 === "" || behaviorTheCol2Part1 === "" || behaviorTheCol2Part2 === "" || behaviorTheCol3Part1 === "" || behaviorTheCol3Part2 === "" || behaviorTheCol4 === ""){
        console.log("Incomplete data ...")
        return
      }
      url = `/api/v3/analytics/export_behavior?region_ids=${selectedRegions}&location_ids=${selectedLocations}&start_date=${startDate}&end_date=${endDate}&text=${searchText}&sort_type=${sortType}&sort_reverse=${sortReverse}&behavior_col1_part1=${behaviorTheCol1Part1}&behavior_col1_part2=${behaviorTheCol1Part2}&behavior_col2_part1=${behaviorTheCol2Part1}&behavior_col2_part2=${behaviorTheCol2Part2}&behavior_col3_part1=${behaviorTheCol3Part1}&behavior_col3_part2=${behaviorTheCol3Part2}&behavior_col4=${behaviorTheCol4}&export=true`
      file_name ="instructor_behavior.csv"

    }else if (activeTab == 'responsiveness'){
      url = `/api/v3/analytics/export_responsiveness?region_ids=${selectedRegions}&location_ids=${selectedLocations}&start_date=${startDate}&end_date=${endDate}&text=${searchText}&sort_type=${sortType}&sort_reverse=${sortReverse}&export=true`
      file_name = "instructor_responsiveness.csv"
    }
    if(url !== ""){
      axiosGetExportRequest(url).then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file_name == "" ? "instructor_data.csv" : file_name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    }else{
      console.log("Empty URL...")
    }
    
  }

  const handleAdjustBehaviorColumn = () => {
    handleOpenBehaviorColumSettingPopup()
  }

  const handleClosepeopleList = (e) => {
    const target = $(e.target)
    if(!target.parents('div#targetshowAnalyticsInstructorslist1').length && !target.parents('div#showAnalyticsInstructorslistInput').length && showAnalyticsInstructorslist){
      setShowAnalyticsInstructorslist(false)
        // showAnalyticsInstructorslist: false,
        // searchDmValue: ''
    }
  }

  useEffect(() => {
    if (searchText) {
      document.body.addEventListener('click', handleClosepeopleList)
    }
    return () => document.body.removeEventListener('click', handleClosepeopleList)
  }, [handleClosepeopleList, searchText]);

  const handleChangeSearch = (e) => {
    let searchTerm = e.target.value.trim()
    setSearchText(e.target.value)
    let classes = analyticsInstructors
    if(searchTerm === ''){
      setAnalyticsInstructor([])
    }else{
      var foundClasses = classes.filter(function (entry) {
        return (entry.full_name.toLowerCase().includes(searchTerm.toLowerCase()));
      });
      if (foundClasses.length > 0){
        setShowAnalyticsInstructorslist(true)
      }
      setAnalyticsInstructor(foundClasses)
    }
    debounceSearchText(e.target.value)
  }

  const handleOnBlurAnalyticsInstructors = (e) => {
    setShowAnalyticsInstructorslist(true)
  }

  const handleOnCLickAnalyticsInstructors = (e) => {
    setShowAnalyticsInstructorslist(true)
    handleChangeSearch(e)
  }

  const handleSelectedAnalyticsInstructor = (e, full_name) => {
    setSearchText(full_name)
    debounceSearchText(full_name)
    setShowAnalyticsInstructorslist(false)
  }

  const debounceSearchText = debounce(val => {
    handleClickOnSearch(selectedLocations, selectedRegions, startDate, endDate, val)
  }, 800);

  return(
    <Fragment>
      <div className='flex gap-4 py-4 flex-wrap'>
        <div className='h-9 flex items-center sm:w-60 w-full filters_box'>
          <Menu as="div" className="w-full relative block text-left ">
            <div>
              <Menu.Button className="inline-flex items-center h-9 justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                Location
                <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                <div className="flex items-center p-2 border-b">
                  <div className=" ml-auto">
                    <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                      Select All
                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {selectedAllRegLoc()} onChange = {(e) => selectAll(e)}/>
                    </label>
                  </div>
                </div>
                <div className="p-2 max-h-60 overflow-y-auto">
                  {regionLocations.map((region, i) =>
                    <div className="py-1" key = {i}>
                      {showFilterOther &&
                        <Menu.Item>
                          {({ active }) => (
                            <label className="flex items-center text-gray-900 text-sm mb-2">
                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => selectRegions(e, region)} value={region?.region?.id} checked={selectedRegions.includes(region.region.id)}/>
                              {region.region.name}
                            </label>
                              
                          )}
                        </Menu.Item>
                      }
                      <div className="ml-5">
                        {region.locations.map((location, j) =>
                        <Menu.Item key = {j}>
                          {({ active }) => (
                            <label className="flex items-center text-gray-900 text-sm mb-2">
                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => selectLocation(e, region)}/>
                              {location.name}
                            </label>
                              
                          )}
                        </Menu.Item>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center p-2 border-t">
                  <div className="flex justify-center items-center w-full px-3 gap-3">
                    <div className=''>
                      <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => setSelLocations()}>Save</a>
                    </div>
                    <div className=''>
                      <a className='bg-white text-gray-700 border border-gray-200 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={() => cancelLocationFilter()}>Cancel</a>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className='flex items-center sm:w-60 w-full'>
          <div className='my-2.5 mr-2.5'>
            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip="true" data-for={`tooltip_for_${activeTab}`} currentitem="false"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
            <ReactTooltip className='max-w-xs text-center' id={`tooltip_for_${activeTab}`} place="top" effect="solid">
              <p>{activeTab == "behavior" ? "The dates represent the class dates. You will be looking at the number of sub requests sent for this instructor for classes occurring in the selected timeframe." : "The dates represent the class dates. You will be looking at the number of sub requests received for this instructor for classes occurring in the selected timeframe."}</p>
            </ReactTooltip>
          </div>
          <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center relative'>
            {/* <input type="text" className='text-sm w-full border-0 h-9 ml-2 focus:outline-none bg-transparent' placeholder='Start Date' /> */}
            <DatePicker
              selected={startDate}
              className="h-9 rounded-md w-full border-0 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
              autoFocus={false}
              dateFormat="M/d/yyyy"
              onChange={(e) => handleStartDateChange(e)}
              calendarType="US" 
              // isClearable={startDate === '' ? false : true}
              placeholderText="Start Date"                    
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-1.5 right-2.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
          </div>
        </div>
        <div className='bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center sm:w-60 w-full relative'>
          {/* <input type="text" className='text-sm w-full border-0 h-9 ml-2 focus:outline-none bg-transparent' placeholder='End Date' /> */}
          <DatePicker
            selected={endDate}
            className="h-9 rounded-md w-full border-0 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
            autoFocus={false}
            dateFormat="M/d/yyyy"
            onChange={(e) => handleEndDateChange(e)}
            calendarType="US" 
            // isClearable={startDate === '' ? false : true}
            placeholderText="End Date"                    
          />
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-1.5 right-2.5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
          </svg>
        </div>
        <div className='sm:w-36'>
          <div className="flex items-center gap-x-4">
            <input type="submit" value="Search" className='font-medium cursor-pointer inline-flex bg-dark-blue text-sm text-white py-2 px-3 rounded-md h-9' onClick={(e) => handleSearchResult(e)}/>
            <button className='cursor-pointer' onClick={() => handleExportData()}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
            </button>
            {activeTab == 'behavior' &&
              <button onClick={() => handleAdjustBehaviorColumn()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 01-.517.608 7.45 7.45 0 00-.478.198.798.798 0 01-.796-.064l-.453-.324a1.875 1.875 0 00-2.416.2l-.243.243a1.875 1.875 0 00-.2 2.416l.324.453a.798.798 0 01.064.796 7.448 7.448 0 00-.198.478.798.798 0 01-.608.517l-.55.092a1.875 1.875 0 00-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 01-.064.796l-.324.453a1.875 1.875 0 00.2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 01.796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 01.517-.608 7.52 7.52 0 00.478-.198.798.798 0 01.796.064l.453.324a1.875 1.875 0 002.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 01-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 001.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 01-.608-.517 7.507 7.507 0 00-.198-.478.798.798 0 01.064-.796l.324-.453a1.875 1.875 0 00-.2-2.416l-.243-.243a1.875 1.875 0 00-2.416-.2l-.453.324a.798.798 0 01-.796.064 7.462 7.462 0 00-.478-.198.798.798 0 01-.517-.608l-.091-.55a1.875 1.875 0 00-1.85-1.566h-.344zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                </svg>
              </button>
            }
          </div>
        </div>
        <div id='showAnalyticsInstructorslistInput' className="relative bg-gray-300 rounded-md sm:w-72 w-full">
          <div className='bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
            <input type="text" className='text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent' placeholder='Search instructor name' value={searchText} onChange={(e) => handleChangeSearch(e)} onBlur={(e) => handleOnBlurAnalyticsInstructors(e)} onClick={(e) => handleOnCLickAnalyticsInstructors(e)}/>
          </div>
          {(analyticsInstructor?.length > 0 && showAnalyticsInstructorslist) &&
            <div id='targetshowAnalyticsInstructorslist1' className="relative block text-left">
              <div className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
                <div className="py-2 max-h-60 overflow-y-auto">
                  <div>
                    {analyticsInstructor.map((user, i) => 
                      <a key = {i} className="flex items-center text-gray-900 text-sm px-3 cursor-pointer hover:bg-gray-100 py-2.5" onClick= {(e) => handleSelectedAnalyticsInstructor(e, user?.full_name)}>
                        <div className='pl-1'>{user?.full_name}</div>
                      </a>
                    )}
                    {analyticsInstructor?.length === 0 &&
                      <div className='text-gray-400 text-center'>No user found</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}