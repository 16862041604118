import React, { Fragment } from "react";

export default function ReasonIcon({classNames, customColour}) {

  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.3327 10C18.3327 14.6024 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6024 1.66602 10C1.66602 5.39763 5.39698 1.66667 9.99935 1.66667C14.6017 1.66667 18.3327 5.39763 18.3327 10ZM9.99935 6.45834C9.42405 6.45834 8.95768 6.92471 8.95768 7.50001C8.95768 7.84518 8.67786 8.12501 8.33268 8.12501C7.9875 8.12501 7.70768 7.84518 7.70768 7.50001C7.70768 6.23435 8.7337 5.20834 9.99935 5.20834C11.265 5.20834 12.291 6.23435 12.291 7.50001C12.291 7.95529 12.1576 8.38132 11.9275 8.73886C11.7834 8.96282 11.615 9.17662 11.4596 9.3697L11.3758 9.47355C11.2469 9.63309 11.1286 9.77954 11.0192 9.92982C10.7496 10.3004 10.6243 10.5789 10.6243 10.8333V11.25C10.6243 11.5952 10.3445 11.875 9.99935 11.875C9.65417 11.875 9.37435 11.5952 9.37435 11.25V10.8333C9.37435 10.1673 9.70136 9.61648 10.0085 9.19438C10.1383 9.01603 10.2805 8.84012 10.4098 8.68017L10.4857 8.58608C10.64 8.39428 10.7714 8.22555 10.8764 8.06246C10.9805 7.90058 11.041 7.70831 11.041 7.50001C11.041 6.92471 10.5746 6.45834 9.99935 6.45834ZM10.6243 13.3333C10.6243 12.9882 10.3445 12.7083 9.99935 12.7083C9.65417 12.7083 9.37435 12.9882 9.37435 13.3333V14.1667C9.37435 14.5119 9.65417 14.7917 9.99935 14.7917C10.3445 14.7917 10.6243 14.5119 10.6243 14.1667V13.3333Z" fill={customColour ? customColour : "#737373"}/>
      </svg>
    </Fragment>
  )
}